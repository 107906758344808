import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'subscriberFullName',
})
export class SubscriberFullNamePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) {
            return '';
        }

        const first_name = value.first_name || '';
        const last_name = value.last_name || '';

        return `${first_name} ${last_name}`;
    }

}
