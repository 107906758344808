import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { environment } from '../environments/environment';

import { CanActivateProductRouteGuard } from './shared/guards/product-route.guard';

interface EnvironmentConfig {
    customRoutesMap: CustomRoutesMap;
    indexRoute: string;
}

type CustomRoutesMap = object;

const environmentConfig = environment['configs'] as any; // TODO(cstroh): No any
const customRoutesMap: CustomRoutesMap = environmentConfig.customRoutesMap || {};
const defaultRoute: string = customRoutesMap[environmentConfig.indexRoute]
    ? customRoutesMap[environmentConfig.indexRoute]
    : environmentConfig.indexRoute;

export const configuredRoutes: Array<Route> = [
    {
        path: customRoutesMap['provider-search'] ? customRoutesMap['provider-search'] : 'provider-search',
        loadChildren: './provider-search/provider-search.module#ProviderSearchModule',
        data: { pageName: 'global', indexRoute: 'provider-search' },
        canActivate: [CanActivateProductRouteGuard],
    },
    {
        path: customRoutesMap['broker'] ? customRoutesMap['broker'] : 'broker',
        loadChildren: './broker-portal/broker.module#BrokerModule',
        data: { pageName: 'global', indexRoute: 'broker' },
        canActivate: [CanActivateProductRouteGuard],
    },
    {
        path: customRoutesMap['provider-portal-legacy'] ? customRoutesMap['provider-portal-legacy'] : 'provider-portal-legacy',
        loadChildren: './provider-portal-legacy/provider-portal.module#ProviderPortalModule',
        data: { pageName: 'global', indexRoute: 'provider-portal-legacy' },
        canActivate: [CanActivateProductRouteGuard],
    },
    {
        path: customRoutesMap['provider-portal'] ? customRoutesMap['provider-portal'] : 'provider-portal',
        loadChildren: './provider-portal/provider-portal.module#ProviderPortalModule',
        data: { pageName: 'global', indexRoute: 'provider-portal' },
        canActivate: [CanActivateProductRouteGuard],
    },
    {
        path: customRoutesMap['member-portal'] ? customRoutesMap['member-portal'] : 'member-portal',
        loadChildren: './member-portal/member-portal.module#MemberPortalModule',
        data: { pageName: 'global', indexRoute: 'member-portal' },
        canActivate: [CanActivateProductRouteGuard],
    },
    {
        path: '**',
        redirectTo: defaultRoute,
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(configuredRoutes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
