import { forwardRef, Inject, Injectable } from '@angular/core';
import { ConfigService, Undetermined } from '../config.service';
import { timer } from 'rxjs/observable/timer';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';

interface GlobalConfig {
    internalSessionTimeout: Undetermined;
}

@Injectable()
export class SessionService {
    configuration;

    // TODO un hardcode these options
    private _count: number = 0;
    private _timeoutSeconds: number = 1800;
    private timerSubscription: Subscription;
    private inifiteSubscription: Subscription;
    private timer: Observable<number>;
    private infiniteTimer: Observable<number>;
    private resetOnTrigger: boolean = false;
    public timeoutExpired: Subject<number> = new Subject<number>();

    constructor(private configService: ConfigService, private authService: AuthService, private http: HttpClient) {
        this._getConfigurations();
        if (this.configuration) {
            if (this.configuration.active) {
                this.startTimer();
            }
            if (this.configuration.keepAlive) {
                this.startKeepAlive();
            }
        }
    }

    /**
     * Needed incase the user role changes mid session / updating the configuration (e.g.: Anon To Client)
     * @private
     */
    private _getConfigurations() {
        try {
            this.configuration = this.configService.getPageConfig<GlobalConfig>('global', this.authService.userRole).internalSessionTimeout;
        } catch (err) {
            console.warn(`Role: ${this.authService.userRole} most likely not set up properly in config.json for internal session timeout`);
            this.configuration = this.configService.getPageConfig<GlobalConfig>('global', 'Anonymous').internalSessionTimeout;
        }
    }

    /**
     * Starting a time using an observable thats less than the session timeout
     * Probably a dumb way to handle this but it needs to be done in a day and works.
     */
    public startKeepAlive() {
        if (this.inifiteSubscription) {
            this.inifiteSubscription.unsubscribe();
        }

        this.infiniteTimer = timer((this._timeoutSeconds - 60) * 1000);
        this.inifiteSubscription = this.infiniteTimer.subscribe(n => {
            // reset configs to make sure role hasnt changed configurations
            this._getConfigurations();
            this.http.get('/user/keep-alive/').subscribe(res => {
                this.resetTimer();
                if (this.configuration.keepAlive) {
                    this.startKeepAlive();
                }
            });
        });
    }

    /**
     * Starting a time using an observable
     */
    public startTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        this.timer = timer(this._timeoutSeconds * 1000);
        this.timerSubscription = this.timer.subscribe(n => {
            this.timerComplete(n);
        });
    }

    /**
     * Bumping timer to full count again
     */
    public resetTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        if (this.configuration) {
            this.timer = timer(this._timeoutSeconds * 1000);
            this.timerSubscription = this.timer.subscribe(n => {
                this.timerComplete(n);
            });
        }
    }

    /**
     * Finishing timer and making call
     */
    private timerComplete(n: number) {
        this.timeoutExpired.next(++this._count);

        if (this.resetOnTrigger) {
            this.startTimer();
        }
    }
}
