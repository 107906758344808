export function UpdateArray(array: Array<any>, obj: any, isIncluded: boolean) {
    if (array.indexOf(obj) > -1 && !isIncluded) {
        RemoveFromArray(array, obj);
    }
    if (array.indexOf(obj) === -1 && isIncluded) {
        array.push(obj);
    }
}

export function RemoveFromArray(array: Array<any>, obj: any) {
    const index = array.indexOf(obj);
    array.splice(index, 1);
}

export function getIndexOfPredicate(array: Array<any>, predicate: any) {
    let x: number = 0;
    do {
        if (predicate(array[x])) return x;
        x++;
    } while (x < array.length);
    return -1;
}

/** Returns the first element of an array. */
export function first<T>(a: Array<T>): T {
    return a[0];
}

/** The element after the first */
export function second<T>(a: Array<T>): T {
    return a[1];
}

/** The final element */
export function last<T>(a: Array<T>): T {
    return a[a.length - 1];
}

/** There are some elements in the array. */
export function some<T>(a: Array<T>): boolean {
    return a && 0 < a.length;
}

/** The array is empty or doesn't exist. */
export function none<T>(a: Array<T>): boolean {
    return !some(a);
}

/** Turn an array of arrays into an array. */
export function flatten<T>(a: Array<Array<T>>): Array<T> {
    return a.reduce((xs: Array<T>, ys: Array<T>) => xs.concat(ys), []);
}

/** Transform an array into a set of unique elements (a set is by definition containing only unique elements).
 * TODO(cstroh): Why is this `Set<string>` instead of `Set<T>`? */
export function uniqueElementsFromArray<T>(a: Array<T>, s: string): Set<string> {
    if (a) return new Set(a.map((t: T) => t[s]));
}
