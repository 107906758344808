import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayHashMap',
    pure: false,
})
export class HashmapValuesPipe implements PipeTransform {

    transform(object: any, keys: any): any {
        if (!object) {
            return [];
        }
        
        if (keys) {
            return Object.keys(object);
        }

        return Object.keys(object).map(value => {
            return object[value];
        });
    }
}
