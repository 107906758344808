import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { dematerialize, materialize, mergeMap } from 'rxjs/operators';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/materialize';
import 'rxjs/add/operator/dematerialize';
import { environment } from '../../../environments/environment';

const config = require('../../../configs/config.json');

/**
 * Mock backend for testing dashboard functionality
 */
@Injectable()
export class ConfigFakeBackendInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!environment.mocks.global.config) {
            return next.handle(request);
        }
        // wrap in delayed observable to simulate server api call
        return of(null).pipe(mergeMap(() => {

            // test api
            if (request.url.endsWith('configs') && request.method === 'GET') {
                return of(new HttpResponse({status: 200, body: config}));
            }
            // pass through any requests not handled above
            return next.handle(request);
        }))

        // call materialize and dematerialize to ensure delay even if an error is thrown
        // (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(materialize())
            .pipe(dematerialize());
    }
}

export const CONFIGS_BACKEND_PROVIDER = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: ConfigFakeBackendInterceptor,
    multi: true
};
