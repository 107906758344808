import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProdGuard implements CanActivate {
    constructor(private router: Router) {}

    /** TODO(cstroh): Does `canActivate` require `next` and `state`? */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        next;
        state;
        if (isDevMode()) return true;
        else {
            this.router.navigate([environment.configs.indexRoute]);
            return false;
        }
    }
}
