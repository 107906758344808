import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { environment } from '../../../environments/environment';
import { ConfigService, Undetermined } from '../config.service';

interface GlobalConfig {
    allowedRoutes: Undetermined;
}

@Injectable()
export class CanActivateProductRouteGuard implements CanActivate {
    constructor(private configService: ConfigService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        state; // TODO(cstroh): Is state required by the `CanActivate` class? https://angular.io/guide/router#guard-the-admin-feature
        const indexRoute: string = environment['configs'].indexRoute;
        const allowedRoutes: Array<string> = this.configService.getPageConfig<GlobalConfig>('global').allowedRoutes;
        if (route.data.indexRoute === indexRoute) return true;
        if (!allowedRoutes || allowedRoutes.indexOf(route.data.indexRoute) === -1) return this._routeDenied();
        return true;
    }

    _routeDenied(): boolean {
        this.router.navigate([environment['configs'].indexRoute]);
        return false;
    }
}
