import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class CompareService {
    public comparisonLimit = 4;
    public entitiesForComparison: Array<any> = [];

    toggleEntityComparison(entity) {

        const index = this.entitiesForComparison.indexOf(entity);
        if (this.isForComparison(entity)) {
            this.entitiesForComparison.splice(index, 1);
            return false;
        }
        if (this.entitiesForComparison.length === this.comparisonLimit) {
            return false;
        }
        this.entitiesForComparison.push(entity);
        return true;
    }

    isForComparison(entity) {
        const index = this.entitiesForComparison.indexOf(entity);

        return index > -1 ? true : false;
    }

    clear() {
        this.entitiesForComparison.forEach(efc => {
            if (efc.isComparing) {
                efc.isComparing = false;
            }
        });
        this.entitiesForComparison = [];
    }
}
