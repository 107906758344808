import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneObject, deepMerge, deepMergeIncludingNulls, getValue } from '@zipari/web-utils';
import { environment } from '../../environments/environment';
import { ROLES } from '../shared/constants/user-roles';
import { Unreliable } from './utilities/Type';


type EnvironmentConfig = Undetermined;

const environmentConfig = environment['configs'] as EnvironmentConfig;

/** A config that nobody bothered to type */
export type Undetermined = any;

@Injectable()
export class ConfigService {
    public configs: Undetermined;
    public default_no_configuration;
    public app: string;
    public activeRoute;
    public initialConfigResponse;
    public appRoute: string;

    constructor(private http: HttpClient) { }

    public handleOverrideOfDefaultConfig(overrideConfig: any, appName: string): Promise<any> {
        return this.importAppConfigJson(appName).then(module => {
            const configJson = module.default;
            let finalAppConfig = cloneObject(configJson);
            finalAppConfig = deepMergeIncludingNulls(finalAppConfig, overrideConfig);
            return finalAppConfig;
        });
    }

    /*
       Handles dynamic import of app-specific config file
   */
    public importAppConfigJson(appName: string): Promise<any> {
        switch (appName) {
            case ('member-portal'):
                return import(/*  webpackMode: "lazy",
                            webpackChunkName: "member-config" */
                    './../member-portal/member-portal.config.json');
            default:
                return new Promise(resolve => resolve());
        }
    }

    /** Unreliable!!! (No guarantee that the return type is the parameter) */
    public getPageConfig<T>(page: string, role?: string): T {
        if (this.configs[page]) {
            return role ? this.configs[page][role] : this.configs[page];
        }
    }

    public getCurrentNoConfigName(role) {
        return this.default_no_configuration[role];
    }

    public setDefaultNoConfiguration(options) {
        const no_config_option = getValue(options, 'no_config');

        // create an object that tells what the app should do if no special 'no configuration' setup is given
        // the 'no configuration' that is passed in will override this default object that gets created
        const default_no_config = {};
        for (const key in ROLES) {
            if (key) {
                default_no_config[ROLES[key]] = 'No configuration found. Please check with your system administrator.';
            }
        }
        this.default_no_configuration = Object.assign(default_no_config, no_config_option || {});
    }

    public getAppConfigs(app: string) {
        let promise;
        if (environmentConfig.configFromApi) {
            promise = this.http.get<any>('config').toPromise();
            promise.then(data => {
                this.assignData(data['APP']);
            });
        } else {
            // TODO(mnewman): this should probably be part of the service
            promise = this.http.get<any>('configs').toPromise();
            promise.then(data => {
                this.assignData(data[app]);
            });
        }
        return promise;
    }

    public getMergedAppConfigs(app: string) {
        const fileConfigsPromise = this.http.get<any>('configs').toPromise();
        const apiConfigsPromise = this.http.get<any>('config').toPromise();
        const mergedConfigPromise = Promise.all([fileConfigsPromise, apiConfigsPromise]);
        mergedConfigPromise.then(([fileData, apiData]) => {
            const mergedConfig = deepMerge(fileData[app], apiData['APP']);
            this.assignData(mergedConfig);
        });
        return mergedConfigPromise;
    }

    private assignData(data) {
        this.configs = data;
    }
}
