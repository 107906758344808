import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { providerDef } from '@angular/core/src/view';
import { stringBuilder } from '@zipari/ui';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators';

import { ConfigService } from '../../../../shared/config.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { Url } from '../../../../shared/types/url.type';
import { first, last, second, uniqueElementsFromArray } from '../../../../shared/utilities/Array';
import { getMonthDateYear } from '../../../../shared/utilities/Dates';
import { nameConcat } from '../../../../shared/utilities/NameConcat';
import { Member, PolicyResult } from '../../benefits/benefits.types';
import { PolicyAndBenefitsService } from '../../benefits/policy-and-benefits.service';
import { Claim } from '../../claims/claim.interface';
import { ApiListResponse } from '../models/api-list-response.model';
import { MemberPolicy } from '../models/member-policy-api-response.model';

import { MemberCoverage, ProductCoverage } from './../../benefits/benefits.types';
import { DropdownConfig } from './../components/defaulted-dropdown/dropdownConfig.types';
import {
    AccountConfig,
    AllMemberQueryParams,
    AllMembers,
    BenefitsConfig,
    ClaimsConfig,
    MemberBenefitPeriods,
    MemberBenefits,
    MemberDetails,
    MemberName,
} from './member.service.types';

type QueryParams = Array<QueryParam>;
type QueryParam = [Field, Value];
type Field = string;
type Value = string;

type NetworkCode = string;
type Zipcode = string;

interface EnrollmentPoliciesResponse {
    results: Array<PolicyResult>;
}

@Injectable()
export class MemberService {
    public get memberDetails() {
        return this._memberDetails.asObservable();
    }

    private _memberId;
    private claims: Array<Claim> = [];
    public _memberDetails = new BehaviorSubject<MemberDetails>(new MemberDetails());
    private _policy: MemberPolicy;
    public member_details: MemberDetails;
    private enrollmentPolicy; // This is not the same as a policy.
    private networkCodes = ['RERE19', 'RERE20'];

    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private logger: LoggerService,
        private policyAndBenefitsService: PolicyAndBenefitsService) {
        this.initEnrollmentPolicies(); // See method docstring.
    }

    /**
     * We are letting components use the enrollment policies response synchronously. There is no way for the member to change their
     * enrollment policy in the course of using the application. We only need to fetch this data once when the user logs in.
     */
    initEnrollmentPolicies() {
        this.http.get('/api/enrollment/policies').subscribe((enrollmentPoliciesResponse: EnrollmentPoliciesResponse) => {
            this.enrollmentPolicy = enrollmentPoliciesResponse.results[0];
        });
    }

    /**
     * Don't call this in the constructor. I don't know why but it was removed already.
     * We needed a method to initialize the member service from other components.
     */
    construct() {
        if (this.memberId) this.initMemberData();
    }

    formattedName(name: MemberName): string {
        return nameConcat(name);
    }

    set memberId(memberId) {
        this._memberId = memberId;
    }

    get memberId() {
        return this._memberId;
    }

    set policy(memberPolicy: MemberPolicy) {
        this._policy = memberPolicy;
    }

    get policy(): MemberPolicy {
        return this._policy;
    }

    public getProductCoverage(productCoverageType?: string): ProductCoverage {
        const pcType = productCoverageType ? productCoverageType : 'medical';
        let productCoverage = this.policy.product_coverages.find(product_coverage => product_coverage.product_coverage_type === pcType);
        if (!productCoverage) productCoverage = this.policy.product_coverages[0];
        return productCoverage;
    }

    public getProductCoverages(productCoverageType?: string): Array<ProductCoverage> {
        if (productCoverageType) {
            return this.policy.product_coverages.filter(product_coverage => product_coverage.product_coverage_type === productCoverageType);
        }
        return this.policy.product_coverages;
    }

    public getMemberCoveragesByProduct(productCoverageType?: string): Array<MemberCoverage> {
        const pcType = productCoverageType ? productCoverageType : 'medical';
        return this.getProductCoverage(pcType).member_coverages;
    }

    public getMemberFilters(filters, productCoverageType?: string) {
        const pcType = productCoverageType ? productCoverageType : 'medical';
        const mc = this.getMemberCoveragesByProduct(pcType);

        filters.map(filter => {
            // dont touch filters unless flaged to add member values
            if (filter.memberValueKey) {
                const mapMember = mc.map(member => {
                    const memberDetails = member.member;

                    // pull required label and value from member object
                    let optionLabel = memberDetails[filter.memberDisplayKey];
                    let optionValue = memberDetails[filter.memberValueKey];
                    // name is an object > change for display
                    if (filter.memberDisplayKey === 'name') {
                        optionLabel = nameConcat(optionLabel);
                    }
                    if (filter.exactValue) {
                        optionValue = optionLabel;
                    }
                    return { label: optionLabel, value: optionValue };
                });

                filter.options = filter.options ? [...filter.options, ...mapMember] : [...mapMember];
            }
        });

        return filters;
    }

    public setProductCoverageTypeFilter(filter: DropdownConfig): DropdownConfig {
        filter.options = [];
        const uniqueTypes: Set<string> = new Set<string>();
        this.policy.product_coverages.map((productCoverage: ProductCoverage) => {
            uniqueTypes.add(productCoverage.product_coverage_type);
        },
        );
        uniqueTypes.forEach((uniqueType: string) => {
            filter.options.push({
                label: uniqueType,
                value: {
                    product_coverage_type: uniqueType,
                },
            });
        });

        return filter;
    }

    public setProductCoverageNameFilter(filter: DropdownConfig, productCoverageType?: string) {
        const pcType = productCoverageType ? productCoverageType : 'medical';
        const productCoverages = this.getProductCoverages(pcType);

        filter.options = [];
        productCoverages.map(pc => {

            filter.options.push({
                label: pc.external_plan_name,
                value: {
                    product_coverage_id: pc.product_coverage_id,
                    product_coverage_name: pc.external_plan_name,
                },
            });

        },
        );
        return filter;
    }

    public setProductCoveragePeriodFilter(filter: DropdownConfig, productCoverageType?: string): DropdownConfig {
        const pcType = productCoverageType ? productCoverageType : 'medical';

        const productCoverages = this.getProductCoverages(pcType);
        this.policyAndBenefitsService.setCoveragePeriod(productCoverages);

        filter.options = [];
        productCoverages.map(pc => {
            filter.options.push({
                label: this.policyAndBenefitsService.formattedCoveragePeriod(pc.coveragePeriod),
                value: {
                    effective_date: pc.effective_date,
                    termination_date: pc.termination_date,
                    product_coverage_id: pc.product_coverage_id,
                },
            });
        });
        return filter;
    }

    public updateProfile(api: string, member: Member): Observable<any> {
        return this.http.patch(api, member);
    }

    private initMemberData() {
        if (this.member_details) {
            this._memberDetails.next(this.member_details);
        } else {
            //  TODO(jduong): cache observable in order to subscribe to same one in multiple places.
            this.getMemberDetails().subscribe(
                member => {
                    this._memberDetails.next(member);
                    this.member_details = member;
                },
                error => this.logger.consoleWarn('Failed to get member', error),
            );
        }
    }

    /**
     * Needed query params:
     * entity_type=provider
     * distance=60
     * network_code=resolveNetworkCode(member) <- todo
     * location=locationZipcode(member)
     * latitude=locationLatLng(member).latitude
     * longitude=locationLatLng(member).longitude
     */
    public getProviderSearchUrl(baseRoute): Url {
        const member = this.toMember(this.enrollmentPolicy);
        const queryParams: QueryParams = [
            ['entity_type', 'provider'],
            ['distance', '60'],
            ['network_code', this.resolveNetworkCode(this.networkCodes)],
            ['location', this.memberZipcode(member)],
            ['latitude', first(this.memberLatLng(member)).toString()],
            ['longitude', second(this.memberLatLng(member)).toString()],
        ];
        return [baseRoute, queryParams.map(fieldValuePair => fieldValuePair.join('=')).join('&')].join('?');
    }
    toMember(enrollmentPolicy): Member {
        return enrollmentPolicy.product_coverages[0].member_coverages[0].member;
    }
    resolveNetworkCode(enrollmentPolicies): NetworkCode {
        return last(this.networkCodes);
    }
    memberZipcode(member): Zipcode {
        return member.addresses[0].zip_code;
    }
    memberLatLng(member: Member): [number, number] {
        const firstAddress = first(member.addresses);
        return [firstAddress.lat, firstAddress.lng];
    }

    public getPharmacySearchUrl(baseRoute): Url {
        const member = this.toMember(this.enrollmentPolicy);
        const queryParams: QueryParams = [
            ['entity_type', 'pharmacy'],
            ['distance', '60'],
            ['network_code', this.resolveNetworkCode(this.enrollmentPolicy)],
            ['location', this.memberZipcode(member)],
            ['latitude', first(this.memberLatLng(member)).toString()],
            ['longitude', second(this.memberLatLng(member)).toString()],
        ];
        return [baseRoute, queryParams.map(fieldValuePair => fieldValuePair.join('=')).join('&')].join('?');
    }

    public getMemberDetails(): Observable<MemberDetails> {
        const config = this.configService.getPageConfig<any>('global');
        const url = stringBuilder(config.memberApi, { member_id: this.memberId });
        return this.http.get<MemberDetails>(url);
    }

    getAllMembers(queryParams: AllMemberQueryParams): Observable<AllMembers> {
        const params = {};
        Object.keys(queryParams).forEach(key => {
            params[key] = queryParams[key];
        });

        const config = this.configService.getPageConfig<any>('global');
        const url = config.allMembersApi;

        return this.http.get<AllMembers>(url, { params });
    }

    public getPolicyData(): Observable<Array<MemberPolicy>> {
        const api = 'api/enrollment/policies';
        const params = { member_number: this.memberId };
        return this.http.get<Array<MemberPolicy>>(api, { params });
    }

    public getMemberPlanSummary() {
        const config = this.configService.getPageConfig<BenefitsConfig>('benefits') as any;
        const url = stringBuilder(config.api.endpoint, { member_id: this.memberId });
        return this.http.get<ApiListResponse<any>>(url);
    }

    public getMemberBenefits(member_id, params?): Observable<ApiListResponse<MemberBenefits>> {
        if (!member_id) {
            member_id = this.memberId;
        }
        const config = this.configService.getPageConfig<AccountConfig>('account') as any;
        const url = stringBuilder(config.api.getBenefitsEndpoint, { member_id });
        return this.http.get<ApiListResponse<any>>(url, { params });
    }

    public getMemberBenefitPeriods(member_id, params?): Observable<ApiListResponse<MemberBenefitPeriods>> {
        if (!member_id) member_id = this.memberId;
        const config = this.configService.getPageConfig<AccountConfig>('account') as any;
        const url = stringBuilder(config.api.getBenefitPeriodsEndpoint, { member_id });
        return this.http.get<ApiListResponse<any>>(url, { params });
    }

    public getMemberClaims(): Observable<Array<Claim>> {
        const config = this.configService.getPageConfig<ClaimsConfig>('claims') as any;
        return this.claims.length
            ? of(this.claims)
            : this.http.get<ApiListResponse<any>>(config.api.endpoint).pipe(
                map(response => response.results),
                tap(claims => (this.claims = claims)),
            );
    }

    public getClaimDetails(claimId): Observable<any> {
        return this.getMemberClaims().map(claims => {
            return claims.find(claim => String(claim.claim_number) === String(claimId)) || {};
        });
    }
}
