import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { GLOBAL_MOCKS_PROVIDER } from '../mocks/globals/globals.mocks';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MemberService } from './member-portal/modules/shared/services/member.service';
import { AuthService } from './shared/auth.service';
import { User } from './shared/auth.service.types';
import { ConfigService } from './shared/config.service';
import { AppConfig } from './shared/modules/config/config.types';
import { AnalyticsService } from './shared/services/analytics.service/analytics.service';
import { LanguagePreferenceService } from './shared/services/language-preference.service/language-preference.service';
import { DEFAULT_TIMEOUT, RequestTimeoutInterceptor } from './shared/services/request-timeout.interceptor';
import { SentryErrorHandler } from './shared/services/sentry.service';
import { WINDOW_PROVIDERS } from './shared/utilities/Window';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
    ],
    providers: [
        ...GLOBAL_MOCKS_PROVIDER,
        {
            provide: APP_INITIALIZER,
            useFactory: resolveConfigUserData,
            deps: [ConfigService, AuthService],
            multi: true,
        },
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: RequestTimeoutInterceptor,
                multi: true,
            },
        ],
        [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        Title,
        AnalyticsService,
        LanguagePreferenceService,
        WINDOW_PROVIDERS,
        MemberService,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule { }

export function resolveConfigUserData(
    configService: ConfigService,
    authService: AuthService,
) {
    const configPromise = environment['configs']['mergeConfigApi']
        ? configService.getMergedAppConfigs(environment['app'])
        : configService.getAppConfigs(environment['app']);
    const userPromise = environment['configs']['ignoreAuth']
        ? Promise.resolve()
        : authService.getUser(environment['configs'] as AppConfig);

    return () => {
        // Ensures logged in user set only after config and user data fetched
        return Promise.all([configPromise, userPromise]).then(
            ([config, user]) => {
                authService.setAppConfig(config);
                if (!environment['configs']['ignoreAuth']) {
                    authService.setLoggedInUser(user as User, environment['configs'] as AppConfig);
                }
            },
        );
    };
}
