import {Injectable, PipeTransform} from '@angular/core';
import {getValue} from '@zipari/ui';
import {pipeTypeMap} from './pipe-map';
import {valueExists} from '../utilities/Object';

export interface PipeFormat {
    pipe?: string | PipeTransform;
    prop?: string;
    fallbackProps?: Array<string>;
    pipeArgs?: any;
    dataSource?: any;
}

@Injectable()
export class PipeService {

    constructor() { }

    public getValueFromPipe(data, pipeStructure: PipeFormat) {
        let returnVal;
        if (pipeStructure.pipe) {
            // determine which pipe we should be using
            const pipe: any = pipeStructure.pipe;
            let pipeToUse;

            if (typeof pipe === 'string') {
                pipeToUse = new pipeTypeMap[pipe]();
            } else {
                pipeToUse = pipe;
            }

            // attempt to get the value from the prop
            let value = getValue(data, pipeStructure.prop);
            // try to fallback on other props if the first one didn't return a value
            if (!valueExists(value) && pipe !== 'ValueGetterPipe' && pipeStructure.hasOwnProperty('fallbackProps')) {
                for (let i = 0; i < pipeStructure.fallbackProps.length; i++) {
                    if (valueExists(value)) {
                        break;
                    }
                    value = getValue(data, pipeStructure.fallbackProps[i]);
                }
            }

            // if we didnt get any type of data, then screw it just make the value the entire data piece
            if (!value && (pipeStructure.pipe === 'DatePipe' || pipeStructure.pipe === 'UTCShortDatePipe')) {
                if (typeof data === 'string') {
                    value = data;
                } else {
                    value = ''; // except putting the entire data piece through a date pipe will break the page
                }
            } else if (!value) {
                value = data;
            }
            // if there are arguments that need to be given into the pipe then send the arguments in other just call the pipe
            if (pipeStructure.pipeArgs) {
                // This is a hack because of some silliness -- this method should be the way we do the pipe
                // Its gotten uglier fuck... really need to get this all on the same page
                if (pipeStructure.pipe === 'ValueGetterPipe') {
                    if (Array.isArray(pipeStructure.pipeArgs)) {
                        returnVal = pipeToUse.pipeServiceTransform(value, ...pipeStructure.pipeArgs);
                    } else {
                        returnVal = pipeToUse.pipeServiceTransform(value, pipeStructure.pipeArgs);
                    }

                    // for just the string builder replacement pipe its possible that we could have an empty space that we want to be within the string replacement.
                    // so we have to try to trim it and then use the fallback props if relevant
                    returnVal = returnVal.trim();
                    if (!returnVal && pipeStructure.fallbackProps) {
                        let new_value;
                        for (let i = 0; i < pipeStructure.fallbackProps.length; i++) {
                            new_value = this.getValueFromPipe(data, Object.assign({}, pipeStructure, {
                                fallbackProps: null,
                                pipeArgs: [pipeStructure.fallbackProps[i]],
                            }));

                            if (valueExists(new_value)) {
                                returnVal = new_value;

                                break;
                            }
                        }
                    }
                } else if (pipeStructure.pipe === 'StringBuilderReplacementPipe') {
                    if (Array.isArray(pipeStructure.pipeArgs)) {
                        returnVal = pipeToUse.transform(pipeStructure.pipeArgs[0], [value]);
                    } else {
                        returnVal = pipeToUse.transform(pipeStructure.pipeArgs, [value]);
                    }
                } else {
                    if (Array.isArray(pipeStructure.pipeArgs)) {
                        returnVal = pipeToUse.transform(value, ...pipeStructure.pipeArgs);
                    } else {
                        returnVal = pipeToUse.transform(value, pipeStructure.pipeArgs);
                    }
                }
            } else {
                returnVal = pipeToUse.transform(value);
            }
        }

        return returnVal || null;
    }
}
