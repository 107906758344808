import {ActiveInactivePipe} from './active-inactive-pipe/active-inactive-pipe';
import {DateYearSwapperPipe} from './date-year-swapper/date-year-swapper.pipe';
import {SafeHtmlPipe} from './safe-html/safe-html.pipe';
import {SubscriberFullNamePipe} from './subscriber-full-name-pipe/subscriber-full-name.pipe';
import {HashmapValuesPipe} from './hash-map-pipe/hash-map.pipe';
import {StringBuilderReplacementPipe} from './string-builder-replacement-pipe/string-builder-replacement.pipe';
import {HiddenNumberPipe} from './hidden-number-pipe/hidden-number.pipe';
import {DatePipe} from '@angular/common';
import {ValueGetterPipe} from './value-getter-pipe/value-getter.pipe';
import {AddressDisplayPipe} from './address-display-pipe/address-display.pipe';
import {DashedDatePipe, FullNamePipe, MonthPipe, UTCShortDatePipe, YesNoPipe, SnakeToTitlePipe, PhoneNumberPipe, ZipAppDisplayPipe} from '@zipari/ui';

export const pipeTypeMap = {
    DatePipe: DatePipe,
    ActiveInactivePipe: ActiveInactivePipe,
    DateYearSwapperPipe: DateYearSwapperPipe,
    MonthPipe: MonthPipe,
    PhoneNumberPipe: PhoneNumberPipe,
    SnakeToTitlePipe: SnakeToTitlePipe,
    UTCShortDatePipe: UTCShortDatePipe,
    YesNoPipe: YesNoPipe,
    FullNamePipe: FullNamePipe,
    SafeHtml: SafeHtmlPipe,
    SubscriberFullNamePipe: SubscriberFullNamePipe,
    HashmapValuesPipe: HashmapValuesPipe,
    HiddenNumberPipe: HiddenNumberPipe,
    StringBuilderReplacementPipe: StringBuilderReplacementPipe,
    ZipAppDisplayPipe: ZipAppDisplayPipe,
    ValueGetterPipe: ValueGetterPipe,
    AddressDisplayPipe: AddressDisplayPipe,
};
