import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';

const { version } = require('../../../../package.json');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
        Sentry.init({
            dsn: 'https://1fe8624852484c849fbf5f23d3386113@sentry.zipari.net/26',
            release: version,
            blacklistUrls: [/local\.zipari/],
            integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
        });
    }

    handleError(error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}
