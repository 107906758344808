import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hiddenNumber',
})
export class HiddenNumberPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        // if we aren't given anything then just return an empty string
        if (!value || typeof value === 'object') {
            return null;
        }

        let shown_amount = 4;
        let replacement_value = 'X';

        if (args) {
            // set the given number for the amount of numbers that should be shown only if we can find it in the arguments
            if (args.hasOwnProperty('shown_amount')) {
                shown_amount = args.shown_amount;
            }

            // set the given value for the replacement value that should be used
            if (args.hasOwnProperty('replacement_val')) {
                replacement_value = args.replacement_val;
            }
        }
        // check to make sure that we actually have to do any type of magic number thing
        if (value.length > shown_amount) {
            let finalized_string = '';

            // loop through the proper number of the replacement values that should be added
            for (let i = 0 ; i < value.length - shown_amount; i++) {
                finalized_string += replacement_value;
            }

            // add the final numbers that should be shown
            finalized_string += value.substring(value.length - shown_amount, value.length);

            // finally return the result
            return finalized_string;
        } else {
            return value;
        }
    }
}
