import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getValue } from '@zipari/web-utils';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { Name } from '../member-portal/modules/shared/services/member.service.types';

import {
    ActualUser,
    ApiError,
    ApiUserResponse,
    AppUserData,
    AuthServiceOptions,
    EnrollUserData,
    ImpersonatedUser,
    Role,
    SetUserPayload,
    User,
} from './auth.service.types';
import { VALID_ROLES } from './constants/user-roles';
import { AppConfig, GlobalConfig } from './modules/config/config.types';
import { Path } from './types/url.type';
import { exists } from './utilities/Exists';
import { httpResponseCode } from './utilities/Http';

type UserRegistration = unknown;

type ChangePasswordPayload = unknown;

interface LoginCredentials {
    username: string;
    password: string;
}

interface ResetPasswordCredentials {
    password: string;
    password_confirm: string;
}

@Injectable()
export class AuthService {
    public actualUser: ActualUser;
    public authServiceOptions: AuthServiceOptions;
    public impersonatedUser: ImpersonatedUser;
    public user: User;

    private appConfig: AppConfig;
    private appUserData$: BehaviorSubject<AppUserData> = new BehaviorSubject(null);

    private readonly changePasswordUrl: Path = 'user/change-password/';
    private readonly forgotPasswordUrl: Path = 'api/user/forgot_password/';
    private readonly resetPasswordUrl: Path = 'user/reset_password/';
    private readonly forgotUsernameUrl: Path = 'user/forgot_username/';
    
    public resetPasswordToken: string;

    showNav: boolean;

    /** The logged in user */
    public get loggedInUser(): ActualUser | ImpersonatedUser {
        return this.impersonatedUser ? this.impersonatedUser : this.actualUser;
    }

    /** The name of the current user's role */
    public get userRole(): Name {
        if (this.noLoggedInUser() || this.loggedInUserHasNoRoles()) return 'Anonymous';
        if (this.loggedInUserHasActiveRole) {
            const role: Name = this.loggedInUser.app_user_data.user_role
                ? this.loggedInUser.app_user_data.user_role
                : this.loggedInUser.app_user_data.data.active_role;
            return role;
        }
        const validRoles: Array<Role> = this.loggedInUser.roles.filter((role: Role) => VALID_ROLES.includes(role.name));
        if (validRoles.length < 1) throw new Error('No valid roles for logged in user');
        return validRoles[0].name;
    }
    noLoggedInUser = (): boolean => !this.loggedInUser;
    loggedInUserHasNoRoles = (): boolean => this.loggedInUser && this.loggedInUser.roles && this.loggedInUser.roles.length === 0;
    loggedInUserHasActiveRole = (): boolean => {
        return (
            exists(this.loggedInUser.app_user_data) &&
            exists(this.loggedInUser.app_user_data.data) &&
            exists(this.loggedInUser.app_user_data.data.active_role)
        );
    };

    /** Whether multiple roles are allowed or not */
    public get dualRolesFunctionality(): boolean {
        return this.noAuthServiceOptions ? false : !this.authServiceOptions.disable_multi_roles; // Should be true
    }
    noAuthServiceOptions = (): boolean => !this.authServiceOptions || Object.keys(this.authServiceOptions).length === 0;

    /** The number of roles the logged in user has */
    public get roleCount(): number {
        return this.loggedInUser ? this.loggedInUser.roles.filter((role: Role) => VALID_ROLES.includes(role.name)).length : 0;
    }

    constructor(private http: HttpClient) {}

    /** The app user, maybe with the init data response */
    // getUser(appConfig: AppConfig): Promise<[User, AppUserData]> | Promise<User> {
    //     return appConfig.userDataSource === 'init_data' ? this.getUserFromInitData() : this.getUserFromApi();
    // }

    getUser(dataSource): Promise<any> {
        if (dataSource.userDataSource === 'init_data') {
            return this.getUserFromInitData();
        } else {
            return this.getUserFromApi();
        }
    }

    /** The app user from the init data API */
    getUserFromInitData(): Promise<[User, AppUserData]> {
        const enrollmentUserPromise: Promise<User> = this.http.get<User>('/api/user/').toPromise();
        enrollmentUserPromise.then((enrollUserData: EnrollUserData) => {
            this.user = enrollUserData.impersonated_user ? enrollUserData.impersonated_user : enrollUserData;
        });
        const initDataPromise: Promise<AppUserData> = this.http.get<AppUserData>('init_data').toPromise();
        initDataPromise.then((initDataResponse: AppUserData) => {
            this.actualUser = initDataResponse.USER_INFO;
            this.impersonatedUser = initDataResponse.USER_INFO.impersonated_user;
        });
        return Promise.all([enrollmentUserPromise, initDataPromise]).then();
    }

    /** The app user from the user API */
    getUserFromApi(): Promise<User> {
        return new Promise((resolve: Function, reject: Function): void => {
            this.http
                .get<ApiUserResponse>('api/user/')
                .toPromise()
                .then((appUserData: AppUserData) => {
                    resolve(appUserData);
                })
                .catch((error: ApiError) => {
                    delete this.actualUser;
                    delete this.user;
                    return error.status === httpResponseCode.get('unauthorized') || error.status === httpResponseCode.get('forbidden') ? resolve() : reject(error);
                });
        });
    }

    /** Takes an app config that is not exactly what is defined in tenant configs. */
    setAppConfig = (config: AppConfig): AppConfig => (this.appConfig = config);

    /** It sets the "actual user" too. */
    setLoggedInUser(user: User, config?: AppConfig): void {
        const globalOptions: GlobalConfig = this.appConfig['global'];
        this.authServiceOptions = globalOptions ? globalOptions.auth_options : {};
        if (!config || (config && config.userDataSource !== 'init_data')) {
            if (user) this.actualUser = { ...this.actualUser, app_user_data: user, roles: user.roles };
            this.user = user;
            this.showNav = true;
        }
    }

    /** Set the name of the app user's role. */
    setUserRole(role: Name): Promise<Subscription> {
        const payload: SetUserPayload = { data: { active_role: role } };
        return new Promise(
            (resolve: Function): Subscription =>
                this.http.put(`api/user/`, payload).subscribe(() => this.getUserFromApi().then(() => resolve())),
        );
    }

    login(credentials: LoginCredentials): Observable<AppUserData> {
        const loginPath: string = getValue(this.appConfig, 'login.loginEndpoint') || '/login/';
        return this.http
            .post<AppUserData>(loginPath, credentials)
            .pipe(tap((appUserData: AppUserData) => this.appUserData$.next(appUserData)));
    }

    logout = (): Observable<unknown> => this.http.post<unknown>('api/user/logout/', {});

    register = (userRegistration: UserRegistration): Observable<unknown> => this.http.post('/user/register/', userRegistration);

    changePassword(payload: { current_password: string; password: string; confirm_password: string }): Observable<unknown> {
        const changePasswordPayload: ChangePasswordPayload = {
            success_url: window.location.href,
            current_password: payload.current_password,
            new_password: payload.password,
            confirm_new_password: payload.confirm_password,
        };
        return this.http.post<string>(this.changePasswordUrl, changePasswordPayload);
    }

    /** An observable of the post request to the reset password API */
    resetPassword(credentials): Observable<unknown> {
        return this.http.post<string>(this.resetPasswordUrl, credentials);
    }

    /** TODO(gdanielsson): Want to pass `next` URL in JSON, but backend refusing at the moment */
    /*** TODO(erho): No longer need to pass 'next' param anymore  */
    sendForgotPasswordEmail = (payload: object): Observable<unknown> => {
        return this.http.post<string>(this.forgotPasswordUrl, payload);
    };

    forgotUsername(email: string): Observable<string> {
        return this.http.post<string>(this.forgotUsernameUrl, { email_address: email });
    }

    setResetPasswordToken(token: string) {
        this.resetPasswordToken = token;
    }

}
