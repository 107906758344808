export const configs = {
    'broker-portal': {
        indexRoute: 'broker',
        userDataSource: 'api',
        mocks: {
            global: { config: true, init_data: false, user_ip: false },
            services: false,
        },
    },
    zshop: {
        indexRoute: 'broker',
        redirectRoute: 'shop',
        customRoutesMap: {
            broker: 'shop',
        },
        mocks: {
            global: { config: true, init_data: false, user_ip: true },
            services: false,
        },
    },
    scout: {
        indexRoute: 'provider-search',
        mocks: {
            global: { config: true, init_data: true, user_ip: true },
            services: false,
        },
        ignoreAuth: true,
    },
    'provider-portal': {
        indexRoute: 'provider-portal',
    },
    'member-portal': {
        indexRoute: 'member-portal',
        mergeConfigApi: true,
        customRoutesMap: {
            ACCOUNT: 'accumulators'
        },
        mocks: {
            global: { config: true, init_data: true, user_ip: true },
            services: false,
        },
    },
};
