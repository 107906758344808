import { Injectable } from '@angular/core';
import {ConfigService, Undetermined} from '../config.service';
import {getValue} from '@zipari/ui';
import {schemaClass, schemaProp} from '@zipari/json-decorators';

@schemaClass()
export class EnrollmentPeriodModel {
    @schemaProp({description: 'The start date of the enrollment period'})
    start: string;

    @schemaProp({description: 'The end date of the enrollment period'})
    end: string;

    @schemaProp({description: 'The display name for this enrollment period'})
    display_name: string;

    @schemaProp({description: 'Special key to be used for this enrollment period'})
    key: string;

    @schemaProp({description: 'Any special logic that needs to be added'})
    special_logic: any;
}

interface GlobalConfig {
    enrollment_configs: Undetermined;
}

@Injectable()
export class EnrollmentPeriodsService {
    public current_enrollment_period;
    public disable_application_submission: boolean;
    public disable_application_text;
    public disable_billing: boolean;
    public disable_billing_text;
    public enrollment_config: any;
    constructor(private configService: ConfigService) {
        this.getEnrollmentConfig();
    }

    getEnrollmentConfig() {
        const current_date = new Date();
        const global = this.configService.getPageConfig<GlobalConfig>('global');
        this.enrollment_config = global.enrollment_configs;
        if (!this.enrollment_config) {
            console.warn('Could not determine enrollment period');

            return;
        }
        try {
            this.current_enrollment_period = this.enrollment_config.filter( (enrollment_period: EnrollmentPeriodModel) => {
                const start_date = new Date(enrollment_period.start);
                const end_date = new Date(enrollment_period.end);

                return current_date >= start_date && current_date <= end_date;
            })[0];
            const application_submit = getValue(this.current_enrollment_period, 'special_logic.application_submit');
            if (application_submit && typeof application_submit.date === 'string') {
                this.disable_application_submission = !(current_date >= new Date(application_submit.date));
            } else if (application_submit && typeof application_submit.value === 'boolean') {
                this.disable_application_submission = !application_submit.value;
            } else {
                this.disable_application_submission = false;
            }

            const billing_submit = getValue(this.current_enrollment_period, 'special_logic.billing_submit');
            if (billing_submit !== null && typeof billing_submit.date === 'string') {
                this.disable_billing = current_date >= new Date(billing_submit.date);
            } else if (billing_submit !== null && typeof billing_submit.value === 'boolean') {
                this.disable_billing = !billing_submit.value;
            } else {
                this.disable_billing = false;
            }

            if (this.disable_application_submission) {
                this.disable_application_text = application_submit.text;
            }
            if (this.disable_billing) {
                this.disable_billing_text = billing_submit.text;
            }
        } catch (err) {
            console.warn('Could not determine enrollment period');
        }

        return this.current_enrollment_period;
    }
}
