import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {getValue} from '@zipari/ui';

@Injectable()
export class DynamicRoutingService {
    constructor() {
    }

    /**
     *
     *
     * TO USE THIS DYNAMIC ROUTING THERE ARE A COUPLE OF STEPS
     *
     * 1) UNDER THE GLOBAL OPTIONS OF THE CURRENT ROLE YOU ARE IN YOU MUST ENABLED DYNAMIC ROUTING LIKE BELOW
     *     - "dynamic_routing_enabled": true,
     *
     * 2) IF THE ROUTE YOU ARE TRYING TO CHANGE IS A PAGE THEN FOLLOW THIS
     *    UNDER THE CURRENT PAGE YOU ARE TRYING TO CHANGE THE ROUTE OF... YOU MUST ADD A "ROUTENAME" THAT SAYS WHAT THE URL SHOULD BE
     *    ANY URL PARAMS ARE INCLUDED IN THIS. EX. ADDING THE BELOW CONFIGURATION TO "CLIENTS" WILL MAKE THE URL CONTACTS INSTEAD OF CLIENTS
     *     - "routeName":"Contacts"
     *
     * 3) IF THE ROUTE YOU ARE TRYING TO CHANGE IS AN APP THEN FOLLOW THIS
     *    UNDER THE GLOBAL CONFIGURATION IN CONFIG.JSON ADD A KEY CALLED APP_ROUTES WHICH IS A MAP THAT HAS THE OLD APP NAME AND THE NEW ONE
     *     - "app_routes" : {  "broker" : "sales"  }
     *
     * */

    entry_url;
    setup_complete;

    /**
     * Stored object that keeps track of the routes that were dynamically changed.
     * at the moment we dont need much with this object but it could be valuable later on.
     * */
    changedRoutes = {};

    /**
     * Use this function whenever trying to set up dynamic routing for an application
     * */
    setupInitialDynamicRouting(router: Router, role: string, appName: string, configs: any) {
        const global_app_routes = getValue(configs, `global.${role}.app_routes`);

        // always handle the app routes first because we need to reset configs to properly do these dynamic routes
        if (global_app_routes) {
            let new_config = router.config;
            new_config = new_config.map((appRoutes, index) => {
                if (global_app_routes[appRoutes.path]) {
                    appRoutes.path = global_app_routes[appRoutes.path];
                }

                return appRoutes;
            });

            // loop through all of the routes were supposed to add and create a new obj that will allow us to redirect to the proper route
            for (let key in global_app_routes) {
                new_config.unshift({path: key, redirectTo: global_app_routes[key]});
            }

            // once we properly built the config
            // reset the router
            router.resetConfig(new_config);

            // loop through all of the individual pages to create the dynamic routing for each page
            for (const key in configs) if (configs.hasOwnProperty(key)) {
                const new_app_name = global_app_routes[appName] || appName;
                const options = configs[key][role];
    
                // only run this if we see a new route name
                if (options && options.routeName) {
                    // check to make sure we didnt already make this routing change
                    // we dont want to do it multiple times because bad things might happen :)
                    if (!this.changedRoutes.hasOwnProperty(key)) {
                        // run the logic to actually go and change this routes path
                        this.changeCurrentChildRoute(router, new_app_name, key, options.routeName);
    
                        // set the current dynamic route name
                        this.changedRoutes[key] = options.routeName;
                    }
                }
            }
    
            // route the user back to the initial url that they came in with
            router.navigate([this.entry_url]).then(() => {
                this.setup_complete = true;
            });
        }
    }

    /**
     * Use this function in order to change a single path from an old path to a new one
     * */
    private changeCurrentChildRoute(router, app, pageName, newPath) {
        let app_routes;

        // try to get the app's specific path
        try {
            app_routes = router.config.filter(appRoutes => appRoutes.path === app)[0]._loadedConfig.routes[0].children;
        } catch (err) {
            console.warn('You tried to change a route of an app that doesnt exist');
        }

        // try to get the current path's children routes so that we can manipulate them
        // get the ones that need to be changed... make sure that we make a new path as well as change the old ones to add a redirect
        // ... yes we're straight up just manipulating the child array
        if (app_routes) {
            try {
                // get the individual pagename where the config was provided
                app_routes.filter((appRoute, index) => {
                    // const regex = RegExp(`(/?)(${oldPath})(/?)`);
                    if (appRoute.data && appRoute.data.pageName === pageName) {
                        app_routes[index].redirectTo = newPath;

                        return true;
                    } else {
                        return null;
                    }
                }).map(child_route => {
                    return {
                        path: newPath,
                        component: child_route.component,
                        data: child_route.data,
                    };
                }).forEach(child_route => {

                    app_routes.unshift(child_route);
                });
            } catch (err) {
                console.error(err);
                console.warn('You tried to change a route that doesnt exist');
            }
        }
    }

    // /**
    //  * handles adding a new route to the current application's router
    //  * */
    // public addRoute(router: Router, path: string, componentRef: any, routeName: string, data?: any) {
    //     // create a new config from the router's config
    //     const new_config = router.config;
    //     // console.log('old route', new_config);
    //
    //     // pop off the ** route so that that is still the fallback at the end
    //     // angular's routing works from top down... so if we dont pop off this route, then this
    //     // route will be used before any custom one that we add
    //     // const fallback_route = new_config.pop();
    //
    //     // always add at least the route name to the dynamic route
    //     const extra_data = {
    //         pageName: routeName,
    //     };
    //
    //     // if we have extra data that we want to add to the current route... add it in
    //     if (data) {
    //         Object.assign(extra_data, data);
    //     }
    //
    //     const new_route = {path: path, component: componentRef, data: extra_data};
    //     // add the new config that was given as an input
    //     new_config.unshift(new_route);
    //
    //     // push back on the fallback route
    //     // new_config.push(fallback_route);
    //
    //     // reset the config that is on the current router
    //     router.resetConfig(new_config);
    // }
}
