export const policies = {
    next: 0,
    previous: 0,
    count: 2,
    results: [
       {
          policy_number: 'ENRP00001444923',
          effective_date: '1/1/2017 12:00:00 AM',
          group: {
             group_name: 'AULTCARE CORPORATION',
             group_number: '150',
          },
          termination_date: '7/31/2032 12:00:00 AM',
          product_coverages: [
             {
                product_coverage_type: 'medical',
                effective_date: '1/1/2017 12:00:00 AM',
                external_plan_id: 'BPLP00000000030',
                external_plan_name_sbc_url: '',
                external_plan_name: 'ZZ - AULTCARE DEFINED OPTION III FAMILY 123118',
                member_coverages: [
                   {
                      member_coverage_id: '536372',
                      termination_date: '12/31/2018 12:00:00 AM',
                      members: [
                         {
                            member_number: 'MBRP00000294556',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'RONALD',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '7/17/2006 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297729',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'MARTY',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '1/25/2010 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      external_plan_id: 'BPLP00000000030',
                      effective_date: '1/1/2017 12:00:00 AM',
                   },
                ],
                termination_date: '12/31/2018 12:00:00 AM',
             },
             {
                product_coverage_type: 'dental',
                effective_date: '1/1/2017 12:00:00 AM',
                external_plan_id: 'BPLP00000000501',
                external_plan_name_sbc_url: '',
                external_plan_name: 'AULTCARE DENTAL',
                member_coverages: [
                   {
                      member_coverage_id: '544860',
                      termination_date: '7/31/2032 12:00:00 AM',
                      members: [
                         {
                            member_number: 'MBRP00000294556',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'RONALD',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '7/17/2006 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297729',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'MARTY',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '1/25/2010 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      external_plan_id: 'BPLP00000000501',
                      effective_date: '1/1/2017 12:00:00 AM',
                   },
                ],
                termination_date: '7/31/2032 12:00:00 AM',
             },
             {
                product_coverage_type: 'vision',
                effective_date: '1/1/2017 12:00:00 AM',
                external_plan_id: 'BPLP00000000501',
                external_plan_name_sbc_url: '',
                external_plan_name: 'Vision Plan Name',
                member_coverages: [
                   {
                      member_coverage_id: '544860',
                      termination_date: '7/31/2032 12:00:00 AM',
                      members: [
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297729',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'MARTY',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '1/25/2010 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      external_plan_id: 'BPLP00000000501',
                      effective_date: '1/1/2017 12:00:00 AM',
                   },
                ],
                termination_date: '7/31/2032 12:00:00 AM',
             },
          ],
          policy_status: 'active',
       },
    ],
 };

 export const benefits = {
    count: 0,
    next: 0,
    previous: 0,
    results: [
      {
        id: 0,
        member_coverages: [
          {
            member_coverage_id: '536372',
            external_plan_id: 'BPLP00000000030',
            effective_date: '1/1/2017 12:00:00 AM',
            termination_date: '7/31/2032 12:00:00 AM',
            members: [
              {
                member_number: 'string',
                member_display_identifier: 'string',
                name: {
                  first_name: 'Jenna',
                  last_name: 'Greer',
                  middle_name: 'string',
                  prefix: 'string',
                  suffix: 'string',
                },
                ssn: 'string',
                gender: 'male',
                birth_date: 'string',
                tobacco_use: true,
                marital_status: 'string',
                ethnicity: 'string',
                home_phone: 'string',
                cell_phone: 'string',
                office_phone: 'string',
                primary_phone_type: 'home',
                email_address: 'string',
                primary_language: 'string',
                secondary_language: 'string',
                has_disability: 'string',
                relationship_to_subscriber: 'Self',
                subscriber_number: 'string',
                addresses: [
                  {
                    street_name_1: 'string',
                    street_name_2: 'string',
                    street_name_3: 'string',
                    city_name: 'string',
                    zip_code: 'string',
                    state: 'string',
                    address_type: 'physical',
                  },
                ],
                privacy_restriction: true,
              },
              {
                member_number: 'string',
                member_display_identifier: 'string',
                name: {
                  first_name: 'Ronald',
                  last_name: 'Grear',
                  middle_name: 'string',
                  prefix: 'string',
                  suffix: 'string',
                },
                ssn: 'string',
                gender: 'male',
                birth_date: 'string',
                tobacco_use: true,
                marital_status: 'string',
                ethnicity: 'string',
                home_phone: 'string',
                cell_phone: 'string',
                office_phone: 'string',
                primary_phone_type: 'home',
                email_address: 'string',
                primary_language: 'string',
                secondary_language: 'string',
                has_disability: 'string',
                relationship_to_subscriber: 'Self',
                subscriber_number: 'string',
                addresses: [
                  {
                    street_name_1: 'string',
                    street_name_2: 'string',
                    street_name_3: 'string',
                    city_name: 'string',
                    zip_code: 'string',
                    state: 'string',
                    address_type: 'physical',
                  },
                ],
                privacy_restriction: true,
              },
            ],
          },
        ],
        accumulators: [
          {
            type: 'amount_used',
            value: '500',
          },
          {
            type: 'amount_remaining',
            value: '1000',
          },
        ],
        benefit_period: {
          id: 0,
          start_date: 'string',
          end_date: 'string',
        },
        created_at: '2020-01-24T16:27:14.294Z',
        updated_at: '2020-01-24T16:27:14.294Z',
        effective_date: '2020-01-24',
        termination_date: '2020-01-24',
        accumulator_type: 'Deductible',
        benefit_type: 'string',
        benefit_applies_to: 'Individual',
        benefit_category: 'string',
        participation_level: 'in_network',
        coverage_level: 'string',
        title: 'string',
        description: 'The amount you pay for covered health care services before your insurance plan starts paying ',
        notes: [

        ],
        total: '1500',
      },
      {
        id: 1,
        member_coverages: [
          {
            member_coverage_id: '536372',
            external_plan_id: 'BPLP00000000030',
            effective_date: '1/1/2017 12:00:00 AM',
            termination_date: '7/31/2032 12:00:00 AM',
            members: [
              {
                member_number: 'MBRP00000297730',
                member_display_identifier: 'AC00100997400',
                name: {
                  first_name: 'Jenna',
                  last_name: 'Greer',
                  middle_name: 'string',
                  prefix: 'string',
                  suffix: 'string',
                },
                ssn: 'string',
                gender: 'male',
                birth_date: 'string',
                tobacco_use: true,
                marital_status: 'string',
                ethnicity: 'string',
                home_phone: 'string',
                cell_phone: 'string',
                office_phone: 'string',
                primary_phone_type: 'home',
                email_address: 'string',
                primary_language: 'string',
                secondary_language: 'string',
                has_disability: 'string',
                relationship_to_subscriber: 'Self',
                subscriber_number: 'string',
                addresses: [
                  {
                    street_name_1: 'string',
                    street_name_2: 'string',
                    street_name_3: 'string',
                    city_name: 'string',
                    zip_code: 'string',
                    state: 'string',
                    address_type: 'physical',
                  },
                ],
                privacy_restriction: true,
              },
              {
                member_number: 'MBRP00000297730',
                member_display_identifier: 'AC00100997400',
                name: {
                  first_name: 'Ronald',
                  last_name: 'Grear',
                  middle_name: 'string',
                  prefix: 'string',
                  suffix: 'string',
                },
                ssn: 'string',
                gender: 'male',
                birth_date: 'string',
                tobacco_use: true,
                marital_status: 'string',
                ethnicity: 'string',
                home_phone: 'string',
                cell_phone: 'string',
                office_phone: 'string',
                primary_phone_type: 'home',
                email_address: 'string',
                primary_language: 'string',
                secondary_language: 'string',
                has_disability: 'string',
                relationship_to_subscriber: 'Self',
                subscriber_number: 'string',
                addresses: [
                  {
                    street_name_1: 'string',
                    street_name_2: 'string',
                    street_name_3: 'string',
                    city_name: 'string',
                    zip_code: 'string',
                    state: 'string',
                    address_type: 'physical',
                  },
                ],
                privacy_restriction: true,
              },
            ],
          },
        ],
        accumulators: [
            {
                type: 'amount_used',
                value: '300',
              },
              {
                type: 'amount_remaining',
                value: '3000',
              },
        ],
        benefit_period: {
          id: 0,
          start_date: 'string',
          end_date: 'string',
        },
        created_at: '2020-01-24T16:27:14.294Z',
        updated_at: '2020-01-24T16:27:14.294Z',
        effective_date: '2020-01-24',
        termination_date: '2020-01-24',
        accumulator_type: 'Out of Pocket',
        benefit_type: 'string',
        benefit_applies_to: 'Family',
        benefit_category: 'string',
        participation_level: 'in_network',
        coverage_level: 'string',
        title: 'string',
        description: 'Costs you pay for covered health care services count toward your out-of-pocket maximum. This may include costs that go toward your plan deductible and your coinsurance. It may also include any copays you owe when you visit doctors.',
        notes: [
        ],
        total: '3300',
      },
    ],
  };

  export const policyMock = {
    next: 0,
    previous: 0,
    count: 2,
    results: [
       {
          policy_number: 'ENRP00001444923',
          effective_date: '1/1/2017 12:00:00 AM',
          group: {
             group_name: 'AULTCARE CORPORATION',
             group_number: '150',
          },
          termination_date: '7/31/2032 12:00:00 AM',
          product_coverages: [
             {
                product_coverage_type: 'medical',
                effective_date: '1/1/2017 12:00:00 AM',
                external_plan_id: 'BPLP00000000030',
                external_plan_name_sbc_url: '',
                external_plan_name: 'ZZ - AULTCARE DEFINED OPTION III FAMILY 123118',
                member_coverages: [
                   {
                      member_coverage_id: '536372',
                      termination_date: '12/31/2018 12:00:00 AM',
                      members: [
                         {
                            member_number: 'MBRP00000294556',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'RONALD',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '7/17/2006 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297729',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'MARTY',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '1/25/2010 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      external_plan_id: 'BPLP00000000030',
                      effective_date: '1/1/2017 12:00:00 AM',
                   },
                ],
                termination_date: '12/31/2018 12:00:00 AM',
             },
             {
                product_coverage_type: 'dental',
                effective_date: '1/1/2017 12:00:00 AM',
                external_plan_id: 'BPLP00000000501',
                external_plan_name_sbc_url: '',
                external_plan_name: 'AULTCARE DENTAL',
                member_coverages: [
                   {
                      member_coverage_id: '544860',
                      termination_date: '7/31/2032 12:00:00 AM',
                      members: [
                         {
                            member_number: 'MBRP00000294556',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'RONALD',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '7/17/2006 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297729',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0010147607E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'MARTY',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '1/25/2010 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      external_plan_id: 'BPLP00000000501',
                      effective_date: '1/1/2017 12:00:00 AM',
                   },
                ],
                termination_date: '7/31/2032 12:00:00 AM',
             },
             {
                product_coverage_type: 'medical',
                effective_date: '1/1/2019 12:00:00 AM',
                external_plan_id: 'BPLP00000001373',
                external_plan_name_sbc_url: '',
                external_plan_name: 'AULTCARE HDHP WITH HSA FAMILY',
                member_coverages: [
                   {
                      member_coverage_id: '742765',
                      termination_date: '7/31/2032 12:00:00 AM',
                      members: [
                         {
                            member_number: 'MBRP00000294556',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'RONALD',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '7/17/2006 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297729',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'MARTY',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '1/25/2010 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: 'AC00100997400',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      external_plan_id: 'BPLP00000001373',
                      effective_date: '1/1/2019 12:00:00 AM',
                   },
                ],
                termination_date: '7/31/2032 12:00:00 AM',
             },
          ],
          policy_status: 'active',
       },
       {
          group: {
             group_name: 'AULTCARE CORPORATION',
             group_number: 'IV00030',
          },
          product_coverages: [
             {
                product_coverage_type: 'vision',
                effective_date: '1/1/2018 12:00:00 AM',
                member_coverages: [
                   {
                      member_coverage_id: '648391',
                      termination_date: '7/31/2032 12:00:00 AM',
                      members: [
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0020150856E',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000294556',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0020150856E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'RONALD',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '7/17/2006 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      external_plan_id: 'BPLP00000000796',
                      effective_date: '1/1/2019 12:00:00 AM',
                   },
                   {
                      member_coverage_id: '648391',
                      external_plan_id: 'BPLP00000000796',
                      members: [
                         {
                            member_number: 'MBRP00000297730',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0020150856E',
                            relationship_to_subscriber: 'Enrollee',
                            name: {
                               first_name: 'JENNA',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '9/22/1979 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                         {
                            member_number: 'MBRP00000294556',
                            addresses: [
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'physical',
                               },
                               {
                                  street_name_1: '2344 ELMWOOD ST',
                                  state: 'OH',
                                  city_name: 'AKRON',
                                  zip_code: '44305',
                                  street_name_2: '',
                                  address_type: 'mailing',
                               },
                            ],
                            member_display_identifier: '0020150856E',
                            relationship_to_subscriber: 'Dependent',
                            name: {
                               first_name: 'RONALD',
                               last_name: 'GREAR',
                               middle_name: '',
                            },
                            birth_date: '7/17/2006 12:00:00 AM',
                            subscriber_number: 'MBRP00000297730',
                            home_phone: '3305881963',
                         },
                      ],
                      effective_date: '1/1/2018 12:00:00 AM',
                   },
                ],
                external_plan_name_sbc_url: '',
                external_plan_name: 'VISION SERVICE PLAN',
                external_plan_id: 'BPLP00000000796',
             },
          ],
          policy_number: 'ENRP00001077966',
          policy_status: 'active',
          effective_date: '1/1/2018 12:00:00 AM',
       },
    ],
 };

 export const benefit_periods = {
    count: 0,
    next: 0,
    previous: 0,
    results: [
      {
        id: 1,
        start_date: '1/1/2018',
        end_date: '1/1/2019',
      },
      {
        id: 2,
        start_date: '2/1/2019',
        end_date: '1/1/2020',
      },
    ],
  };
