import {configs} from '../configs/configuration';

// Quick hack -- should probably fix this to make it more global reusable
function mergeMocksConfigs() {
    const mocks = {global: {config: true, init_data: false, user_ip: false}, services: false};
    Object.assign(mocks, configs['scout']['mocks']);
    return mocks;
}
export const environment = {
    app: 'scout',
    configs: configs['scout'],
    production: false,
    mocks: mergeMocksConfigs()
};
