import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CXCaptureConfig, GAConfig, ProductPrefixes, workflowContext } from '@zipari/web-utils';
import { CXCaller } from 'zipari-cx/dist';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../auth.service';
import { ConfigService } from '../../config.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { AnalyticsContext, validCXEvents } from './validCXEvents';

const { version } = require('../../../../../package.json');

export class UTM_PARAMS {
    utm_source?: string = '';
    utm_medium?: string = '';
    utm_campaign?: string = '';
    utm_term?: string = '';
    utm_content?: string = '';

    constructor(params) {
        const keys = Object.keys(this);

        keys.forEach(key => {
            if (params[key]) {
                this[key] = params[key];
            } else {
                delete this[key];
            }
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    CXCaller: CXCaller;

    CX_Config: CXCaptureConfig;
    GA_Config: GAConfig;

    workflowContext: workflowContext;

    constructor(
        public googleAnalyticsService: GoogleAnalyticsService,
        public configService: ConfigService,
        public authService: AuthService,
        public http: HttpClient,
    ) {
        const globalConfig = this.configService.getPageConfig<any>('global');

        if (globalConfig.analytics) {
            if (globalConfig.analytics.cx_capture && this.checkForCXCaptureConfigs(globalConfig.analytics.cx_capture)) {
                this.CX_Config = globalConfig.analytics.cx_capture;
                this.CXCaller = new CXCaller({ endpoint: this.CX_Config.endpoint }, { source_system_name: this.CX_Config.source_system_name });
            } else {
                console.warn('No CX Capture configuration provided');
            }

            if (globalConfig.analytics.google_analytics) {
                this.GA_Config = globalConfig.analytics.google_analytics;
            } else {
                console.warn('No Google analytics configuration provided');
            }
        } else {
            console.warn('No analytics configuration provided');
        }
    }

    private checkForCXCaptureConfigs(config: CXCaptureConfig) {
        const newConfig = new CXCaptureConfig(config);
        const validConfigs = Object.keys(newConfig);

        let valid = true;

        validConfigs.forEach(key => {
            if (!config[key]) {
                console.warn(`${key} not provided in config`);

                valid = false;
            }
        });

        return valid;
    }

    private formatBrowserContext() {
        const context = {};

        context['timestamp'] = new Date().toISOString();

        try {
            // attempt to retrieve timezone
            context['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

            context['page'] = Object.assign({
                url: window.location.href,
            });

            context['app'] = {
                name: this.CX_Config.source_system_name,
                version: version,
            };
        } catch (err) {
        }

        return context;
    }

    private formatBody(name: validCXEvents, dictionary_attributes: any) {
        let result = {};

        try {
            const authentication_flag = !!this.authService.loggedInUser;

            let consumer_id;
            if (authentication_flag) {
                consumer_id = this.authService.loggedInUser.app_user_data.broker_id
                    || this.authService.loggedInUser.app_user_data.member_id
                    || this.authService.loggedInUser.app_user_data.user_name
                    || null;
            } else {
                consumer_id = this.workflowContext ? this.workflowContext.webuser_id : null;
            }

            const session_id = this.workflowContext ? this.workflowContext.web_session_id : null;
            const source_system_name = this.CX_Config.source_system_name;
            const context = this.formatBrowserContext();

            result = {
                name: `${this.determineProductPrefix(environment['app'])}${name}`,
                source_system_name,
                authentication_flag,
                dictionary_attributes,
            };

            // only provide the cohort id if we have one
            if (consumer_id) {
                result['consumer_id'] = consumer_id;
            }

            if (context) {
                result['context'] = context;
            }

            if (session_id) {
                result['session_id'] = session_id;
            }
        } catch (err) {
            console.error(err);
        }

        return result;
    }

    private sendCXEvent(event_key: validCXEvents, dictionary_attributes: any) {
        const body: any = this.formatBody(event_key, dictionary_attributes);
        this.http.post('api/cx/capture/events/', {
            payload: body,
            key: `${this.determineProductPrefix(environment['app'])}${event_key}`,
        }).subscribe();
    }

    /** A product prefix is used to indicate to cx capture team which product is being used...
     * our code generically utilizes these keys so that we do not have to concern ourselves
     * with adding the product prefix within our code */
    private determineProductPrefix(product) {
        let productPrefix = '';

        if (ProductPrefixes[product]) {
            productPrefix = ProductPrefixes[product];
        } else {
            console.warn('No configuration for product');
        }

        return productPrefix;
    }

    public dispatchAnalytics(keys: AnalyticsContext, dictionary_attributes: any = {}) {
        if (keys) {
            if (keys.CXKey && this.CX_Config) {
                this.sendCXEvent(keys.CXKey, dictionary_attributes);
            }
        }
    }
}
