import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ConfigService } from './shared/config.service';
import { DynamicRoutingService } from './shared/services/dynamic-routing.service';

interface GlobalConfig {
    title: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

    /** Don't do work in the constructor http://misko.hevery.com/code-reviewers-guide/flaw-constructor-does-real-work/ */
    constructor(
        public locationRef: Location,
        private titleService: Title,
        private configService: ConfigService,
        private dynamicRouting: DynamicRoutingService,
    ) {}

    ngOnInit(): void {
        this.dynamicRouting.entry_url = this.locationRef.path();
        this.titleService.setTitle(this.configService.getPageConfig<GlobalConfig>('global').title);
    }
}
