import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { nameConcat } from '../../..//shared/utilities/NameConcat';
import { ConfigService } from '../../../shared/config.service';
import { first, flatten, second } from '../../../shared/utilities/Array';
import { capitalize } from '../../../shared/utilities/Text';
import { MemberDetails } from '../shared/services/member.service.types';

import { Member, MemberCoverage, Name, PlanBenefit, PlanType, Policies, PolicyResult, ProductCoverage } from './benefits.types';
import { CoveragePeriod, Plan, PlanDetail, PrimaryCareProvider } from './medical/medical.types';
import { benefit_periods, benefits } from './policies.mocks';
/** TODO(cstroh): Move all direct API calls to `MembersApiService`. Use NgRX for the love of God. */

@Injectable({
    providedIn: 'root',
})
export class PolicyAndBenefitsService {
    memberDetails: MemberDetails; // Members API
    policies: Policies; // Policies API

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        @Inject(LOCALE_ID) private locale: string, // https://angular.io/guide/i18n#i18n-pipes
    ) {}

    getPlans(planType: PlanType): Observable<Array<Plan>> {
        const api_implementation = this.configService.getPageConfig('global')['api_implementation'] || 'default';

        return this.http.get<Policies>(`/api/enrollment/policies/`).map(
            (policies: Policies) => this.toPlans(policies, api_implementation, planType),
            (error: unknown) => console.error(error),
        );
    }
    toPlans(policies: Policies, api_implementation: string, planType: PlanType): Array<Plan> {
        const productCoverages: Array<ProductCoverage> = this.toProductCoverages(policies).filter(
            (productCoverage: ProductCoverage) => this.transformProductCoverageType(productCoverage) === planType,
        );
        const plans: Array<Plan> = api_implementation !== 'default' ? this.v2Plans(productCoverages) : this.defaultPlans(productCoverages);
        return plans;
    }
    defaultPlans(productCoverages: Array<ProductCoverage>): Array<Plan> {
        return productCoverages.map((productCoverage: ProductCoverage) => ({
            name: productCoverage.plan_information.name,
            coveragePeriod: productCoverage.termination_date
                ? this.toCoveragePeriod(productCoverage.effective_date, productCoverage.termination_date)
                : this.toCoveragePeriod(productCoverage.effective_date),
            effectiveDate: new Date(productCoverage.effective_date),
            details: this.toPlanDetails(productCoverage),
        }));
    }
    v2Plans(productCoverages: Array<ProductCoverage>): Array<Plan> {
        return productCoverages.map((productCoverage: ProductCoverage) => ({
            name: productCoverage.external_plan_name,
            coveragePeriod: productCoverage.termination_date
                ? this.toCoveragePeriod(productCoverage.effective_date, productCoverage.termination_date)
                : this.toCoveragePeriod(productCoverage.effective_date),
            effectiveDate: new Date(productCoverage.effective_date),
            memberCoverages: productCoverage.member_coverages,
            details: [],
        }));
    }

    setCoveragePeriod(coverages: Array<ProductCoverage> | Array<MemberCoverage>): void {
        for (const coverage of coverages) {
            coverage.coveragePeriod = coverage.termination_date
                ? this.toCoveragePeriod(coverage.effective_date, coverage.termination_date)
                : this.toCoveragePeriod(coverage.effective_date);
        }
    }

    setMemberPlanStatus(coveragePeriod: CoveragePeriod, member: Member): void {
        const dateNow: Date = new Date();
        const isPreActive: boolean = dateNow < first(coveragePeriod);
        const isActive: boolean = !second(coveragePeriod) || (dateNow >= first(coveragePeriod) && dateNow <= second(coveragePeriod));
        const isInactive: boolean = second(coveragePeriod) && dateNow > second(coveragePeriod);

        if (isPreActive) {
            member.planStatus = `pre-active`;
        }
        if (isActive) {
            member.planStatus = `active`;
        }
        if (isInactive) {
            member.planStatus = `inactive`;
        }
    }

    formattedCoveragePeriod(coveragePeriod: CoveragePeriod): string {
        if (!second(coveragePeriod)) {
            return `${this.MMddyyyy(first(coveragePeriod))} - present`;
        }
        return `${this.MMddyyyy(first(coveragePeriod))} - ${this.MMddyyyy(second(coveragePeriod))}`;
    }

    formatMemberProviderNames(members: Array<Member>): void {
        for (const member of members) {
            if (member.name) {
                member['formatted_name'] = this.formattedName(member.name);
            }
            if (member.pcp_list) {
                this.formatPrimaryCareProviderNames(member.pcp_list);
            }
        }
    }

    formatPrimaryCareProviderNames(pcpList: Array<PrimaryCareProvider>): void {
        for (const primaryCareProvider of pcpList) {
            if (primaryCareProvider.provider && primaryCareProvider.provider.name) {
                primaryCareProvider.provider['formatted_name'] = this.formattedName(primaryCareProvider.provider.name);
            }
        }
    }

    formattedName(name: Name): string {
        return nameConcat(name);
    }

    MMddyyyy(date: Date): string {
        return formatDate(date, 'MM/dd/yyyy', this.locale);
    }

    toCoveragePeriod(effectiveDate: string, terminationDate?: string): CoveragePeriod {
        let start: Date;
        let end: Date;
        start = new Date(effectiveDate);
        terminationDate ? (end = new Date(terminationDate)) : (end = null);
        return [start, end];
    }

    toPlanDetails(productCoverage: ProductCoverage): Array<PlanDetail> {
        return productCoverage.plan_information.plan_benefits.map((planBenefit: PlanBenefit) => ({
            benefitName: planBenefit.benefit_category_display_text,
            inNetworkBenefitValue: planBenefit.co_payment,
            outOfNetworkBenefitValue: planBenefit.out_of_pocket,
        }));
    }
    toProductCoverages(policies: Policies): Array<ProductCoverage> {
        return flatten(policies.results.map((policyResult: PolicyResult) => policyResult.product_coverages));
    }

    toDefaultCoverage(productCoverages: Array<ProductCoverage>, defaultCoverageType?: string): ProductCoverage {
        const default_coverage_type: string = defaultCoverageType ? defaultCoverageType : 'medical';
        return productCoverages.find((policy: ProductCoverage) => {
            const policy_type: string = this.transformProductCoverageType(policy);
            return policy_type === default_coverage_type;
        });
    }

    transformProductCoverageType = (productCoverage: ProductCoverage): string => {
        if (typeof productCoverage.product_coverage_type === 'string') {
            return productCoverage.product_coverage_type.toLowerCase();
        } else if (productCoverage.product_coverage_type_display) {
            return productCoverage.product_coverage_type_display.toLowerCase();
        }
    };
    getBenefits(memberId: string, benefitPeriodId: number | string): Observable<any> {
        return of(benefits).pipe(map(benefits => benefits.results));
    }

    getBenefitsPeriod(memberId: string): Observable<any> {
        // return this.http.get(`api/members/${memberId}/benefits?benefit_period_id=${benefitPeriodId}`);
        return of(benefit_periods).pipe(map(benefit_periods => benefit_periods.results));
    }
}
