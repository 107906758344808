export function assignIn(...args: any[]) {
    const destObject = arguments[0];
    for (let i = 1; i < arguments.length; i++) {
        const props = Object.keys(arguments[i]);
        const srcObject = arguments[i];
        props.forEach(function (prop) {
            destObject[prop] = srcObject[prop];
        });
    }
    return destObject;
}

export function mapByProperty(srcArray: Array<any>, propertyString: string): Array<any> {
    return srcArray.map(item => {
        return getPropertyValue(item, propertyString);
    });
}

export function valueExists(value) {
    if (typeof value === 'string') {
        value = value.trim();
    }

    return ((value && typeof value === 'object' && Object.keys(value).length > 0) || (value && typeof value !== 'object'));
}

export function getPropertyValue(srcObject, propertyString): any {
    let propArray = propertyString.split('.');
    const value = srcObject[propArray[0]];
    if (propArray.length === 1) {
        return value;
    }
    if (value === null) {
        return null;
    }

    propArray = propArray.slice(1);
    return getPropertyValue(value, propArray.join('.'));
}

export function flattenProperty(obj: any, prop: string) {
    if (!obj[prop]) {
        return;
    }
    Object.keys(obj[prop]).forEach(key => {
        obj[`${prop}_${key}`] = obj[prop][key];
    });
    delete obj[prop];
}

export function deleteNullKeys(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null) {
            delete obj[key];
        }
    });
}

export function objectToQueryString(obj: any) {
    const params = new URLSearchParams();
    Object.keys(obj).forEach(key => {
        params.set(key, obj[key]);
    });
    return params.toString();
}
