import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'activeInactive',
})
export class ActiveInactivePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (value) {
            case 'A':
                return 'Active';
            case 'I':
                return 'Inactive';
            default:
                return '';
        }
    }
}
