import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getValue } from '@zipari/ui';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DataFilterService {

    constructor(private http: HttpClient) { }

    filterDataServerSide(
        method: 'GET' | 'POST',
        url: string,
        filters: Array<Partial<{
            key: string,
            valueGetter: string,
        }>>,
        filterValues: any,
        args = {}): Observable<any> {
        const params = { ...args };
        const body = { ...args };
        let isFilterValue = false;
        filters.forEach(({ key, valueGetter = key }) => {
            const filterValue = getValue(filterValues, valueGetter);
            if (filterValue) {
                isFilterValue = true;
                if (method === 'GET') {
                    params[key] = filterValue;
                } else if (method === 'POST') {
                    body[key] = filterValue;
                }
            }
        });
        if (isFilterValue) {
            return this.http.request(method, url, { body, params });
        } else {
            return of(null);
        }
    }

    filterDataClientSide(
        data: Array<any>,
        filters: Array<Partial<{
            key: string,
            isExact: boolean,
            isRangeLow: boolean,
            isRangeHigh: boolean,
            valueGetter: string,
        }>>,
        filterValues: any): Array<any> {
        let filteredData = this.makeShallowCopy(data);
        filters.forEach(({ key, isExact = false, isRangeLow = false, isRangeHigh = false, valueGetter = key }) => {
            const filterValue = getValue(filterValues, key);
            filteredData = filteredData.filter(row => isExact
                ? this.compareExactValues(getValue(row, valueGetter), String(filterValue))
                : (isRangeLow ? this.compareRangeLowValues(getValue(row, valueGetter), String(filterValue))
                    : (isRangeHigh ? this.compareRangeHighValues(getValue(row, valueGetter), String(filterValue))
                        : this.comparePartialValues(getValue(row, valueGetter), String(filterValue))
                    )),
            );
        });
        return filteredData;
    }

    private makeShallowCopy(data: Array<any>): Array<any> {
        if (data) {
            return [...data];
        } else {
            return [];
        }
    }

    // data value must be string, filter value can be null
    private compareExactValues(dataValue = '', filterValue: string): boolean {
        return !filterValue || dataValue.toLowerCase() === filterValue.toLowerCase();
    }

    // data value must be string, filter value can be null
    private comparePartialValues(dataValue = '', filterValue: string): boolean {
        return !filterValue || dataValue.toLowerCase().includes(filterValue.toLowerCase());
    }

    private compareRangeLowValues(dataValue = '', filterValue: string): boolean {
        return !filterValue || dataValue.toLowerCase() >= (filterValue.toLowerCase());
    }
    private compareRangeHighValues(dataValue = '', filterValue: string): boolean {
        return !filterValue || dataValue.toLowerCase() <= (filterValue.toLowerCase());
    }
}
